<template>
  <div>
    <FilterOverview title="Daten-Upload" :showFilterInitially="false">
      <template #table="{ tableHeight }">
        <div class="card card-body">
          <div class="mb-2">
            <div class="mb-1">Bitte lade die Daten als CSV-Datei (.csv) hoch, um sie zu verarbeiten.</div>
            <div class="d-flex" style="justify-content: space-between">
              <b-input-group style="width: 50%" id="test">
                <b-input-group-prepend is-text id="foo">
                  <i class="fas fa-file-csv"></i>
                </b-input-group-prepend>
                <b-form-file
                  ref="formFileUpload"
                  id="form-csv-File"
                  accept=".csv"
                  :value="null"
                  :multiple="false"
                  placeholder="Wähle eine CSV-Datei aus"
                  @change="handleFileChange"
                  browse-text="Datei auswählen"
                ></b-form-file>
              </b-input-group>

              <b-button
                v-if="showGrid"
                :disabled="disabledUploadButton"
                @click="upload"
                :variant="disabledUploadButton ? 'secondary' : 'success'"
              >
                Upload der Daten
              </b-button>
            </div>
          </div>

          <div>
            Datenformat: {{ datenformatText }}
            <AgGridVue
              v-if="showGrid"
              :style="{ height: tableHeight - 65 + 'px' }"
              rowHeight="50px"
              class="ag-theme-alpine m-0 p-0"
              :rowData="rowData"
              :defaultColDef="defaultColDef"
              :columnDefs="columnDefs"
              :rowClassRules="rowClassRules"
              @grid-ready="onGridReady"
              :statusBar="statusBar"
              @cellValueChanged="onCellValueChanged"
            ></AgGridVue>
          </div>
        </div>
      </template>
    </FilterOverview>
  </div>
</template>

<script>
import FilterOverview from '@/components/common/filter-overview.vue';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-enterprise';
import StatusBarComponent from '@/components/flugverfuegbarkeit/status-bar-ag-grid.vue';
import apiService from '@/core/common/services/api.service';

export default {
  components: {
    FilterOverview,
    AgGridVue,
    StatusBarComponent,
  },
  data() {
    return {
      showGrid: false,
      disabledUploadButton: true,
      gridApi: null,
      gridColumnApi: null,
      columnDefs: [],
      rowData: [],
      statusBar: null,
      count: null,
      datenformatText: '',
      headers: [],
      defaultColDef: {
        flex: 1,
        sortable: false,
        resizable: false,
        suppressMenu: true,
      },
      katalogbestellungHeaders: [
        {
          key: 'gender',
          value: 'Anrede',
        },
        { key: 'given_name', value: 'Vorname' },
        { key: 'family_name', value: 'Nachname' },
        { key: 'street_address', value: 'Straße und Hausnummer' },
        { key: 'postal_code', value: 'PLZ' },
        { key: 'address_locality', value: 'Ort' },
        { key: 'address_country', value: 'Land' },
        { key: 'email_address', value: 'E-Mail Adresse' },
        { key: 'catalogues', value: 'Katalog' },
      ],
      erwartetePaxHeaders: [
        { key: 'reisetermin', value: 'Reisetermin' },
        { key: 'erw_Pax', value: 'Erw. Pax' },
      ],
      rowClassRules: {
        'red-address': row => {
          return this.isDataInvalid(row.data);
        },
      },
      genderMapping: {
        MALE: 'Herr',
        FEMALE: 'Frau',
        DIVERSE: 'Divers',
      },
    };
  },
  created() {
    this.statusBar = {
      statusPanels: [{ statusPanel: 'StatusBarComponent', key: 'statusBarCompKey', align: 'left' }],
    };
  },
  watch: {
    rowData: {
      deep: true,
      handler() {
        this.checkRedRows();
      },
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      this.updateStatusBar(this.count);
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = e => {
        const content = e.target.result;
        const fileData = this.parseCSV(content);
        this.importDataToGrid(fileData);
      };
      reader.readAsText(file);
    },
    parseCSV(content) {
      const separator = content.includes(';') ? ';' : ',';
      const rows = content.split('\n').filter(row => row.trim() !== '');
      return rows.map(row => row.split(separator).map(cell => cell.trim()));
    },
    richtigeHeaderAuswaehlen(csvHeaders) {
      const isKatalogbestellung = this.katalogbestellungHeaders.every(header =>
        csvHeaders.includes(header.key)
      );
      if (isKatalogbestellung) {
        return {
          type: 'Katalogbestellung',
          headers: this.katalogbestellungHeaders,
        };
      }

      const isErwartetePax = this.erwartetePaxHeaders.every(header => csvHeaders.includes(header.key));
      if (isErwartetePax) {
        return {
          type: 'Erwartete Pax',
          headers: this.erwartetePaxHeaders,
        };
      }

      return {
        type: 'Unbekanntes Datenformat',
        headers: [],
      };
    },
    importDataToGrid(fileData) {
      if (!fileData || fileData.length === 0) return;

      const csvHeaders = fileData[0];
      const headerInfo = this.richtigeHeaderAuswaehlen(csvHeaders);

      this.datenformatText = headerInfo.type;

      if (headerInfo.type !== 'Unbekanntes Datenformat') {
        this.showGrid = true;
        const columns = this.getColumnDefs(headerInfo.headers);
        this.columnDefs = columns;

        // header werden "abgeschnitten"
        const gridData = fileData.slice(1);
        this.rowData = this.dataAnordnen(gridData, csvHeaders, headerInfo.headers);
        this.count = this.rowData.length;

        this.gridApi.setRowData(this.rowData);
        this.updateStatusBar(this.rowData.length);
      } else {
        this.columnDefs = [];
        this.rowData = [];
      }
    },
    getColumnDefs(headers) {
      const deleteColumn = {
        headerName: '',
        field: 'deleteButton',
        width: 50,
        pinned: 'left',
        cellRenderer: params => {
          if (this.isDataInvalid(params.data)) {
            const button = document.createElement('button');
            button.innerHTML = '<i class="fas fa-times"></i>';
            button.className = 'btn btn-sm';
            button.addEventListener('click', () => this.removeRow(params.data));
            return button;
          }
          return '';
        },
        cellStyle: {
          display: 'flex',
          'align-items': 'center',
          'justify-content': 'center',
        },
      };
      const dataColumns = headers.map(header => {
        const column = {
          headerName: header.value,
          field: header.key,
          editable: true,
        };

        if (header.key === 'gender') {
          column.valueFormatter = params => {
            return this.genderMapping[params.value];
          };
        }

        return column;
      });

      if (this.datenformatText === 'Erwartete Pax') {
        return [...dataColumns];
      } else {
        return [deleteColumn, ...dataColumns];
      }
    },
    dataAnordnen(gridData, csvHeaders, benoetigteHeader) {
      const mappedGrid = gridData.map(row => {
        // für jede row wird ein neues Objekt erstellt
        const rowObject = {};
        benoetigteHeader.forEach(header => {
          // hier werden die spalten den benötigten Headern zugeordnet
          const columnIndex = csvHeaders.indexOf(header.key);
          if (columnIndex >= 0 && columnIndex < row.length) {
            rowObject[header.key] = row[columnIndex];
          } else {
            rowObject[header.key] = '';
          }
        });
        return rowObject;
      });
      let compareFn = (a, b) => {
        const sortOrderA = this.isDataInvalid(a) ? 0 : 1;
        const sortOrderB = this.isDataInvalid(b) ? 0 : 1;
        return sortOrderA - sortOrderB;
      };
      const sortedGrid = mappedGrid.sort(compareFn);

      return sortedGrid;
    },
    updateStatusBar(displayedRowsCount) {
      if (!this.gridApi) return;
      const statusBarComponent = this.gridApi?.getStatusPanel('statusBarCompKey');

      if (statusBarComponent) {
        statusBarComponent.setRowCount(displayedRowsCount);
        statusBarComponent.setOdataCount(this.count);
      }
    },
    async upload() {
      if (this.datenformatText === 'Erwartete Pax') {
        const updateValues = this.rowData.reduce((acc, curr) => {
          const reisetermin = curr['reisetermin'];
          const erwartetePax = parseInt(curr['erw_Pax']);
          if (reisetermin && !isNaN(erwartetePax)) {
            acc[reisetermin] = erwartetePax;
          }
          return acc;
        }, {});

        apiService.put('Reisetermin/bulkUpdateErwartetePax', { updateValues }).then(res => {
          console.log(res);
          this.toast('Erwartete Pax gespeichert!');
        });
      } else if (this.datenformatText === 'Katalogbestellung') {
        const katalogData = this.transformKatalogData(this.rowData);
        apiService
          .post(
            'https://api.skr.de/zoho-add-katalogbestellung?code=baJzwQrnm3LfcuAEp65ge_F3VNfajbZ8IWorWhWNCpBkAzFuXADnFg%3D%3D',
            katalogData
          )
          .then(res => {
            console.log(res);
            this.toast('Katalogbestellung wurde hochgeladen');
          });
      }
      this.$refs['formFileUpload'].reset();
      this.showGrid = false;
      this.datenformatText = '';
    },
    isDataInvalid(row) {
      if (!row) {
        return false;
      }
      if (this.datenformatText === 'Katalogbestellung') {
        const requiredFields = [
          'gender',
          'given_name',
          'family_name',
          'street_address',
          'postal_code',
          'address_locality',
          'address_country',
          'email_address',
          'catalogues',
        ];

        const isMissingRequiredField = requiredFields.some(field => !row[field]);
        if (isMissingRequiredField) return true;

        const isGenderInvalid =
          row.gender &&
          !Object.keys(this.genderMapping).includes(row.gender) &&
          !Object.values(this.genderMapping).includes(row.gender);

        const isAddressInvalid = row.street_address.length > 25;
        const isGivenNameInvalid = row.given_name.length > 20;
        const isFamilyNameInvalid = row.family_name.length > 20;
        const isPostalCodeInvalid = row.postal_code.length > 8;
        const isAddressLocalityInvalid = row.address_locality.length > 20;
        const isCountryInvalid = row.address_country.length > 2;

        const hasRequiredCatalogue = row.catalogues
          .split(',')
          .map(catalogue => catalogue.trim())
          .some(catalogue => {
            const fernMatch = catalogue.match(/Fern_(\d{4})/);
            const europaMatch = catalogue.match(/Europa_(\d{4})/);

            return (
              // schaut, ob die 4-stellige Zahl >= 2025 ist
              (fernMatch && parseInt(fernMatch[1]) >= 2025) ||
              (europaMatch && parseInt(europaMatch[1]) >= 2025)
            );
          });

        return (
          isGenderInvalid ||
          isAddressInvalid ||
          !hasRequiredCatalogue ||
          isGivenNameInvalid ||
          isFamilyNameInvalid ||
          isPostalCodeInvalid ||
          isAddressLocalityInvalid ||
          isCountryInvalid
        );
      }

      return false;
    },
    checkRedRows() {
      if (!this.rowData || this.rowData.length === 0) return;

      const hasRedRows = this.rowData.some(data => this.isDataInvalid(data));
      this.disabledUploadButton = hasRedRows;
    },
    removeRow(row) {
      if (this.gridApi) {
        this.gridApi.applyTransaction({ remove: [row] });
        // Update counts after removal
        this.count = this.gridApi.getDisplayedRowCount();
        // das wird benötigt, damit der button sich auch färbt
        this.rowData = this.gridApi.getModel().rowsToDisplay.map(row => row.data);

        this.updateStatusBar(this.count);
      }
    },
    onCellValueChanged(params) {
      params.api.refreshCells({
        rowNodes: [params.node],
        columns: ['deleteButton'],
        force: true,
      });
    },
    transformKatalogData(katalogData) {
      return katalogData.map(item => ({
        zipcode: item.postal_code,
        firstName: item.given_name,
        lastName: item.family_name,
        country: item.address_country,
        city: item.address_locality,
        street: item.street_address,
        eMail: item.email_address,
        salutation: this.genderMapping[item.gender],
        selectedCatalogues: item.catalogues
          ? item.catalogues.split(',').map(catalogue => catalogue.trim())
          : [],
        newsletterSubscribe: true,
      }));
    },
  },
};
</script>
<style>
.red-address {
  background-color: #ffe2e5 !important;
}
</style>
